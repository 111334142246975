import React, { useCallback, useEffect, useRef, useState } from 'react';
import widgetPlaceHolder from '../../components/placeholder-widget';
import system from '../../system';
import { useGlobalStore } from './use-store';

const useForceUpdate = () => {
  const [, u] = useState<number>();
  return useCallback(() => u(performance.now()), []);
};

let queueWidgetTimeout: number;
const queueWidgetUpdate: (() => any)[] = [];

function doQueueWidgetUpdate(cb: () => void) {
  if (queueWidgetUpdate.indexOf(cb) === -1) queueWidgetUpdate.push(cb);
  if (!queueWidgetTimeout) queueWidgetTimeout = self.setTimeout(doWidgetUpdate, 10);
}

function doWidgetUpdate() {
  queueWidgetTimeout = 0;
  for (const cb of queueWidgetUpdate) cb();
  queueWidgetUpdate.length = 0;
}
/**
 * `useWidget` watches and returns the given widget model
 */
export function useWidget(type: string | undefined): null | typeof React.Component {
  const path = useGlobalStore((state) => (type && state.pkg[type]) || type, [type]);
  const forceUpdate = useForceUpdate();
  const widgetRef = useRef<any>();
  useEffect(() => {
    if (path) {
      return system.watch(path, () => doQueueWidgetUpdate(forceUpdate));
    }
    return () => {};
  }, [path]);

  const mod = path && system.syncImport(path);

  if (mod) widgetRef.current = (type && mod[type]) || mod.default;

  const widget = widgetRef.current;
  if (widget && !widget.displayName) widget.displayName = type;
  return widget || widgetPlaceHolder;
}
