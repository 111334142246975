import React, { HTMLAttributes } from 'react';
import { useModel, useWidget } from '../core/hooks';
import { useInteractionTriggers, useInteractionEvents } from '../core/context/interactions';
import ErrorBounds from './error-bounds';
import { Addons } from './addon';
import { isString } from '../utils';
import { ModelProvider } from '../core/context';

interface WidgetNodeProps {
  /** Model key */
  id: string;
  // model: IContent;
  container?: HTMLDivElement;
  children?: React.ReactNode;
  // hostRef: React.RefObject<HTMLDivElement>;
  className?: string;
  fixed?: boolean;
  section?: boolean;
}

function WidgetNode({ id, section, fixed, className }: WidgetNodeProps) {
  const model = useModel(id);
  const Widget = useWidget(model?.type);
  const hostRef = React.useRef<HTMLDivElement>(null);
  const modelId = model?.virtualKey || model?.key || id;

  // If not instance of master component, model?.key will be KEY(11)
  // If instance of master component, model?.key will be KEY(11)-MASTERKEY(11)
  // If nested instance of master component, model?.key will be KEY(11)-MASTERKEY(11)-MASTERKEY(11)

  // Attach interaction triggers
  const [domListeners, widgetTag, widgetAttrs] = useInteractionTriggers(
    modelId,
    hostRef,
    model?.attrs?.tag,
  );

  // Attach interaction events
  const [widgetHidden, widgetSticky, stickyOffset] = useInteractionEvents(modelId, hostRef);

  if (!model || !model.type) return null;

  let cl = '__' + model.type + (className ? ' ' + className : '');
  let contentCl = model.type;
  let inlineStyle = model.style;
  if (model.cl) contentCl += ' ' + model.cl;

  if (widgetHidden === true) {
    cl += ' __hide';
  } else if (widgetHidden === false) {
    cl += ' __show';
  }

  if (widgetSticky === true) {
    cl += ' __sticky';
    inlineStyle = { ...inlineStyle, top: stickyOffset };
  }

  let childrenCl = '__c';

  const contentProps: HTMLAttributes<HTMLDivElement> = {
    id: modelId + 'c',
    className: contentCl,
  };

  if (isString(model.master)) {
    cl += ` ${model.master}`;
    contentProps.className += ` ${model.master}`;
  } else if (model.master) cl += ` ${modelId}`;

  if (model.fromModel) {
    contentProps.className += ` ${model.fromModel}`;
    cl += ` ${model.fromModel}`;
  }

  if (fixed) cl += ' __f';
  if (section) {
    cl += ' __s';
    childrenCl += ' __sc';
  } else if (model.html || (Widget && (Widget as any).static)) cl += ' __pre'; // Check if widget has added static attribute;

  if (model.html) contentProps.dangerouslySetInnerHTML = { __html: '' };

  if (model.type === 'frame' && !section) {
    contentProps.className += ' __wc __c';

    return (
      <ModelProvider value={model}>
        {React.createElement(
          'vev',
          {
            id: modelId,
            className: cl,
            style: inlineStyle,
            ref: hostRef,
            ...domListeners,
          },
          <Addons addons={model.actions} hostRef={hostRef}>
            {React.createElement(
              widgetTag,
              { ...contentProps, ...(model.attrs || {}), ...widgetAttrs },
              <>
                {model.children?.map((id) => (
                  <WidgetNode key={id} id={id} />
                ))}
              </>,
            )}
          </Addons>,
        )}
      </ModelProvider>
    );
  }

  const content = React.createElement(
    'w',
    contentProps,
    model.html || !Widget ? undefined : (
      <Widget section={section} {...model.content} hostRef={hostRef} />
    ),
  );

  // Need to use create element to get rid of error of not supported jsx element
  return React.createElement(
    'vev',
    {
      id: modelId,
      className: cl,
      style: inlineStyle,
      ref: hostRef,
      key: modelId + model.actions?.join() || '',
      ...domListeners,
    },
    <ErrorBounds>
      {React.createElement(
        widgetTag,
        { className: '__wc', ...(model.attrs || {}), ...widgetAttrs },
        <Addons addons={model.actions} hostRef={hostRef}>
          <ModelProvider value={model}>
            {content && (model.pin ? <div className="__pc">{content}</div> : content)}
            {(section || (model.children?.length || 0) > 0) && (
              <div className={childrenCl}>
                {model.children?.map((id) => (
                  <WidgetNode key={id} id={id} />
                ))}
              </div>
            )}
          </ModelProvider>
        </Addons>,
      )}
    </ErrorBounds>,
  );
}

export default WidgetNode;
