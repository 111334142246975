import { IContent } from 'vev';
import React, { useContext, useMemo, createContext } from 'react';
import { isString } from '../../utils';

type ModelsContextModel = { [key: string]: IContent };
type VirtualModelContextModel = {
  masterKey: string;
  instanceKey: string;
  instanceKeyChain: string[];
  overrides: { [modelKey: string]: any };
};

const createKeyMap = (models: IContent[]) => {
  const keyMap: { [key: string]: IContent } = {};
  models.forEach((model) => {
    keyMap[model.key] = model;
  });
  return keyMap;
};

const ModelsContext = createContext<ModelsContextModel | null>(null);
const ModelContext = createContext<IContent>({ key: '' });
const VirtualModelContext = createContext<VirtualModelContextModel | null>(null);

export const ModelProvider = ({
  value,
  children,
}: {
  value: IContent;
  children: React.ReactNode;
}) => {
  const masterKey = value.master;
  if (isString(masterKey)) {
    return (
      <ModelContext.Provider value={value}>
        <VirtualModelProvider
          overrides={value.childContent || {}}
          instanceKey={value.key}
          masterKey={masterKey}
        >
          {children}
        </VirtualModelProvider>
      </ModelContext.Provider>
    );
  }
  return <ModelContext.Provider value={value}>{children}</ModelContext.Provider>;
};

export function ModelsProvider({
  models,
  children,
}: {
  models: IContent[];
  children: React.ReactNode;
}) {
  const modelMap = useMemo(() => createKeyMap(models), [models]);
  return <ModelsContext.Provider value={modelMap}>{children}</ModelsContext.Provider>;
}

export function VirtualModelProvider({
  overrides,
  masterKey,
  instanceKey,
  children,
}: {
  overrides?: { [modelKey: string]: any };
  masterKey: string;
  instanceKey: string;
  children: React.ReactNode;
}) {
  const virtualParent = useContext(VirtualModelContext);

  const virtualModel: VirtualModelContextModel = useMemo(() => {
    const instanceKeyChain = virtualParent?.instanceKeyChain.slice() || [];
    instanceKeyChain.unshift(instanceKey);

    const parentOverrides = virtualParent?.overrides || {};
    const newOverrides = { ...overrides };

    for (const parentKey in parentOverrides) {
      if (newOverrides[parentKey]) {
        // Parent override is the top instance, so that should override the new overrides
        newOverrides[parentKey] = { ...newOverrides[parentKey], ...parentOverrides[parentKey] };
      } else newOverrides[parentKey] = parentOverrides[parentKey];
    }

    return {
      masterKey,
      instanceKey,
      instanceKeyChain,
      overrides: newOverrides,
    };
  }, [masterKey, instanceKey, virtualParent, overrides]);

  return (
    <VirtualModelContext.Provider value={virtualModel}>{children}</VirtualModelContext.Provider>
  );
}

export function useContextModel(key?: string): IContent | undefined {
  const models = useContext(ModelsContext);
  const contextState = useContext(ModelContext);
  const virtualModel = useContext(VirtualModelContext);
  const model = models?.[key || ''];
  const master = models?.[model?.master?.toString() || ''];

  return useMemo(() => {
    if (!key) return contextState;
    const instanceKeyChain = virtualModel?.instanceKeyChain || [];

    const virtualKey =
      !instanceKeyChain?.length || instanceKeyChain.includes(key)
        ? key
        : `${key}-${instanceKeyChain.join('-')}`;

    let override = virtualModel?.overrides[key];

    for (let i = 0; i < instanceKeyChain.length - 1; i++) {
      const path = `${key}-${instanceKeyChain.slice(0, i + 1).join('-')}`;
      if (virtualModel?.overrides[path]) {
        override = { ...override, ...virtualModel?.overrides[path] };
      }
    }

    if (override || master) {
      const cl = [];

      if (override?.type) cl.push(override.type);
      else if (model?.type) cl.push(model.type);

      if (override?.master) cl.push(override.master);
      else if (master) cl.push(master.key);

      if (override?.preset) cl.push(override.preset);
      else if (model?.preset) cl.push(model.preset);

      // prevent children/actions from overrides
      const actions = model?.actions || [];
      const children = master?.children || model?.children || [];
      const contentChildren = model?.content?.children || [];

      return {
        ...master,
        ...model,
        ...override,
        actions,
        children,
        virtualKey,
        fromModel: key,
        cl: cl.join(' '),
        content: {
          ...master?.content,
          ...model?.content,
          ...override?.content,
          children: contentChildren,
        },
      };
    } else if (virtualModel) {
      return {
        ...model,
        fromModel: key,
        key: virtualKey,
      };
    }
    return model;
  }, [key, model, virtualModel, contextState, master]);
}

export function useModels() {
  return useContext(ModelsContext);
}
